//Animações inicio
const topBg = document.querySelector('.top__bg');
const topTitle = document.querySelector('.top__title');
const topSubtitle = document.querySelector('.top__subtitle');
const textHeader = document.querySelector('.top__text');
const headerButton = document.querySelector('.top__button__position');
const contact = document.querySelector('#contact');
const infoProject = document.querySelectorAll('.info__project');

// const mobileTitle = document.querySelector('#mobile__title');
// const swiperAnimate = document.querySelector('.swiper');


const titleInfo = document.querySelectorAll('.info-text');
const textsInfo = document.querySelectorAll('.info-text-content');

const clock = document.querySelector('.clock');
/* const handShake = document.querySelector('.hand'); */
const building = document.querySelector('.building');

const counter1 = document.getElementById('counter1');
const counter2 = document.getElementById('counter2');
const counter3 = document.getElementById('counter3');

for (let index = 0; index < infoProject.length; index++) {
  infoProject[index].style.opacity ="0"
}

building.style.animationDelay = "0.5s"
clock.style.animationDelay = "0.5s"


headerButton.style.opacity = "0"
headerButton.style.animationDelay = "0.1s"
// mobileTitle.style.animationDelay = "1s"
// swiperAnimate.style.animationDelay = "1.5s"

clock.addEventListener('animationend', () => {
  for (let index = 0; index < infoProject.length; index++) {
    infoProject[index].classList.add('animate__animated', 'animate__fadeIn')
  }
});

for (let index = 0; index < infoProject.length; index++) {
  infoProject[index].addEventListener('animationend', () => {
    const infoProjectCard = document.querySelector('.info__project__container')
    if (infoProjectCard && typeof infoProjectCard !== "undefined") {
      const observer = new IntersectionObserver((entries) => {

        increment(0, 70.5, counter1);
        increment(0, 25, counter2);
        /* increment(0, 90.5, counter3); */ //está com um bug no valor 90, se for 90, ele vai chainAnimationOnHeaderButtonara 89, se for 91 fica em 91
      })

      observer.observe(infoProjectCard)
    }

    function increment(i, max, element) {
      if (i > max) return;
      setTimeout(function () {
        element.innerText = Math.round(i);
        increment(i + (max / 100), max, element);
      }, 10)
    }

    headerButton.classList.add('animate__animated', 'animate__fadeIn');
    headerButton.addEventListener('animationend', chainAnimationOnHeaderButton)
  })
}

topBg.classList.add('animate__animated', 'animate__bounceInDown')
building.classList.add('animate__animated', 'animate__bounceInLeft');
clock.classList.add('animate__animated', 'animate__backInRight');
/* handShake.classList.add('animate__animated', 'animate__backInRight'); */

headerButton.classList.add('animate__animated', 'animate__fadeIn');
headerButton.addEventListener('animationend', chainAnimationOnHeaderButton)

function chainAnimationOnHeaderButton() {
  headerButton.style.opacity = "1"
  headerButton.style.animationDelay = "0s"
  headerButton.classList.remove('animate__fadeIn')
  headerButton.removeEventListener("mousedown", chainAnimationOnHeaderButton, true)
}

// if (mobile && typeof mobile !== "undefined"){ 

//   const mobileObserver = new IntersectionObserver ((entries) => {
//     const [entry] = entries;
//     if (entry.isIntersecting && !mobile.classList.contains('animate__animated')) {

//       mobileTitle.classList.add('animate__animated', 'animate__lightSpeedInLeft');
//       swiperAnimate.classList.add('animate__animated', 'animate__lightSpeedInRight');
//     } 
//     /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
//   })

//   const mobileObserverOptions = {
//     root: null,
//     threshold: 1,
//     rootMargin: "-100px",
//   };

//   mobileObserver.observe(mobile, mobileObserverOptions)
// }

// Titles animation
if (titleInfo.length && typeof titleInfo !== "undefined") {
  titleInfo.forEach(titleElement => {
    titleElement.style.opacity = "0"
    titleElement.style.animationDelay = "0.5s"

    const titleInfoObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !titleElement.classList.contains('animate__animated')) {
        titleElement.classList.add('animate__animated', 'animate__fadeIn');
      }
      /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
    })

    const titleInfoObserverOptions = {
      root: null,
      threshold: 1,
      rootMargin: "-100px",
    };

    titleInfoObserver.observe(titleElement, titleInfoObserverOptions)
  });
}
// Text sections animation
if (textsInfo.length && typeof textsInfo !== "undefined") {
  textsInfo.forEach(textElement => {
    textElement.style.opacity = "0"
    textElement.style.animationDelay = "0.5s"

    const textInfoObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !textElement.classList.contains('animate__animated')) {
        textElement.classList.add('animate__animated', 'animate__fadeIn');
      }
      /* contact.classList.add('animate__animated', 'animate__rotateInDownRight'); */
    })

    const textInfoObserverOptions = {
      root: null,
      threshold: 1,
      rootMargin: "-100px",
    };

    textInfoObserver.observe(textElement, textInfoObserverOptions)
  });
}

//Animações fim

//send email start
// begin alert popup
var el = document.getElementById("alert-pup");

function alertPup(title, message, classMessage) {
  el.animate(
    [{ tranfsform: "tralateY(0px)" }, { tranfsform: "tralateX(-300px)" }],
    {
      duration: 3000,
      iteration: 1,
    }
  );

  el.innerHTML = `
    <div class="toast ${classMessage}">
      <div>
        <h2>${title}</h2>
        <p>${message}</p>
      </div>
    </div>`;

  setTimeout(() => cleanPup(), 6000);
}

function cleanPup() {
  el.innerHTML = "";
}
// end alert popup

// begin contact form
function contactForm() {
  //
  function setSpanError(bool, elInput, elErrorSpan) {
    if (bool) {
      elInput.classList.add("form-error-message");
      elErrorSpan.classList.add("active");
    } else {
      elInput.classList.remove("form-error-message");
      elErrorSpan.classList.remove("active");
    }
  }

  // begin input name
  const nameInput = document.getElementById("contact-name");
  const nameErrorSpan = document.getElementById("error-input-name");

  function isInvalidName() {
    const nameValidator = /[A-Za-z]{3}/;
    let isNameValid = nameValidator.test(nameInput.value);

    if (isNameValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input name

  // begin input email
  const emailInput = document.getElementById("contact-mail");
  const emailErrorSpan = document.getElementById("error-input-email");

  function isInvalidEmail() {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = emailValidator.test(emailInput.value.toLowerCase());

    if (isEmailValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input email

  // begin mensagem
  const messageInput = document.getElementById("contact-mensage");
  const messageErrorSpan = document.getElementById("error-input-mensage");

  function isInvalidMessage() {
    if (messageInput.value == null || messageInput.value == "") {
      return true;
    } else {
      return false;
    }
  }
  // end mensagem

  // begin input telefone
  const phoneInput = document.getElementById("contact-phone");
  const phoneErrorSpan = document.getElementById("error-input-phone");

  function isInvalidPhoneNumeber() {
    const phoneValidator =
      /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
    let isPhoneNumerValid = phoneValidator.test(phoneInput.value.toLowerCase());

    if (isPhoneNumerValid) {
      return false;
    } else {
      return true;
    }
  }

  function maskPhoneInput() {
    let inputValue = phoneInput.value;

    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/^(\d{2})(\d)/g, "($1) $2");
    inputValue = inputValue.replace(/(\d)(\d{4})$/, "$1-$2");

    phoneInput.value = inputValue;
  }

  phoneInput.oninput = maskPhoneInput;
  // end input telefone

  //
  function handleSpanError() {
    setSpanError(isInvalidName(), nameInput, nameErrorSpan);
    setSpanError(isInvalidEmail(), emailInput, emailErrorSpan);
    setSpanError(isInvalidPhoneNumeber(), phoneInput, phoneErrorSpan);
    setSpanError(isInvalidMessage(), messageInput, messageErrorSpan);
  }

  //
  function validateInputFromForm() {
    if (
      isInvalidName() ||
      isInvalidEmail() ||
      isInvalidPhoneNumeber() ||
      isInvalidMessage()
    ) {
      return false;
    } else {
      return true;
    }
  }

  // begin action send message
  function sendMessageLPO(e) {
    e.preventDefault();
    handleSpanError();
    if (validateInputFromForm())
      sendEmail();
  }

  function startLoadingOnButton(btn) {
    btn.disabled = true;
    btn.classList.add('fa-fade');
    btn.innerText = 'Enviando...'
  } 

  function endLoadingOnButton(btn) {
    btn.classList.remove('fa-fade');
    btn.innerText = 'Enviado!'
  }

  const buttonSend = document.querySelector(".button-send");
  buttonSend.onclick = sendMessageLPO;
  // end action send message

  // TODO: 3- validar retorno do envio de email
  async function sendEmail(e) {
    //
    if (e && e instanceof Event) {
      e.preventDefault();
    }

    const functions = firebase.functions();
    // functions.useEmulator("localhost", 5001);
    var sendMessageLPO = functions.httpsCallable("sendMessageLPO");
    var sendGoogleSheetLPO = functions.httpsCallable("sendGoogleSheetLPO");

    const _data = {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
      message: messageInput.value
    };

    // handle data message

    // messageInput && messageInput.value
    //   ? (_data.message = `Telefone para contato: ${phoneInput.value}. Mensagem: ${messageInput.value}.`)
    //   : (_data.message = "Telefone para contato: " + phoneInput.value + ".");

    // _data.message = _data.message + ":" + fuctionInput.value;
    startLoadingOnButton(buttonSend)

    grecaptcha.ready(() =>
      grecaptcha
        .execute("6LdPjBspAAAAAKm2zdmL9ELNvOeRo3ETdace_kJ9", {
          action: "submit",
        })
        .then((token) => {
          //
          _data.token = token;

          // action sendMessageLPO
          sendMessageLPO({ ..._data })
            .then((response) => {
              window.location.href =
                "https://lpo.targetwork.com.br/obrigado";
              // TODO: cleanFormContact();
            })
            .catch((error) => {
              alertPup(
                "Erro",
                `Houve um erro no envio da mensagem. 
                            Tente novamente mais tarde, ou nos contate por nossas redes sociais!`,
                "pup-error"
              );
            })
            .finally(() => {
              // = true;
              endLoadingOnButton(buttonSend);
            });

          sendGoogleSheetLPO({ ..._data })
            .then((response) => {
              console.log("response googleSheet", response);
            })
            .catch((error) =>
              console.log("error sendGoogleSheetLPO", error)
            );
        })
        .catch((err) => {
          // Promise.reject(err)
          // console.log(`ERROR 4323 ::: ${err}`);
        })
    );

    return;
    // TODO: tratar retorno do erro
    /* if(error) {
          return changePopupText(`Houve um erro no envio da mensagem. 
            Tente novamente mais tarde, ou nos contate por nossas redes sociais!`); 
            canDeactivatePopup = true; 
      } */

    return false;
  }
}

contactForm();
// end contact form

//send email finish

// begin faq section
function faqSection() {
  const faqList = document.querySelectorAll(".faq-list");
  function activeItem(el) {
    if (el.classList.contains("active")) {
      el.classList.remove("active");
    } else {
      el.classList.add("active");
    }
  }

  faqList.forEach((el) => (el.onclick = () => activeItem(el)));
}

faqSection();
// end faq section